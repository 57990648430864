<template>
  <div class="coupon-exchange-setting">
    <PageTitle
      title="票券使用紀錄"
      btn="匯出使用紀錄"
      btn2="匯出新增紀錄"
      @btnClick="modal.exportUsage = true"
      @btn2Click="modal.exportAdded = true"
    />

    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">篩選</p>
        <el-select v-model="search.status" class="test" clearable @change="refresh(true)">
          <el-option
            v-for="option in couponStatusConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
        <el-select v-model="search.filterTimeType" class="test" clearable @change="refresh(true)">
          <el-option
            v-for="option in filterTimeCategoryConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
        <el-date-picker
          v-model="search.range"
          :disabled="!search.filterTimeType"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="refresh(true)"
        />
      </div>
      <div class="filter-row">
        <p class="label">搜尋</p>
        <SearchTextInput class="test2" :value.sync="search.code" placeholder="請輸入票券編號" @refresh="refresh(true)" />
        <MemberSearch class="test2" :model.sync="search.member" @change="refresh(true)" />
        <SearchTextInput class="test2" :value.sync="search.exchangeCode" placeholder="請輸入兌換碼" @refresh="refresh(true)" />
      </div>
      <div class="filter-row">
        <p class="label">排序</p>
        <el-select v-model="search.orderBy" clearable @change="refresh(true)">
          <el-option
            v-for="option in orderByOptionsConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </div>
    </div>

    <CouponUsageRecordTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <CouponExportOptionModal
      v-if="modal.exportUsage"
      title="匯出使用紀錄"
      @close="modal.exportUsage = false"
      @confirm="onExport($event, 'used')"
    />
    <CouponExportOptionModal
      v-if="modal.exportAdded"
      title="匯出新增紀錄"
      @close="modal.exportAdded = false"
      @confirm="onExport($event, 'added')"
    />
    <!-- <ProcessDialog
      v-if="exportState.modal"
      :title="exportState.title"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :percentage="exportState.percentage"
      @close="exportState.modal = false, resetExport()"
    /> -->

    <ExportDialog
      v-if="exportState.modal"
      :title="exportTypeLabel"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, unref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import CouponUsageRecordTable from './components/CouponUsageRecordTable.vue'
import { orderByOptionsConfig, couponStatusConfig, filterTimeCategoryConfig, couponOriginTypeConfig, couponTypeConfig } from '@/config/coupon'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import CouponExportOptionModal from './components/CouponExportOptionModal.vue'
import { GetCouponRecord, GetCouponRecordCount } from '@/api/lottery/couponRecord'
import { get, omitBy, flatten } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useExport } from '@/use/export'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'CouponUsageRecord',
  components: {
    PageTitle,
    CouponUsageRecordTable,
    SearchTextInput,
    MemberSearch,
    CouponExportOptionModal,
    ExportDialog,
  },
  setup (props) {
    const exportTypeLabel = ref('')
    const modal = reactive({
      exportUsage: false,
      exportAdded: false,
    })
    const router = useRouter()
    const route = useRouter()
    const { simpleFetch } = useFetch()
    const { exportState, resetExport, getExportData, getExportDataOld, totalDataCount, totalPercentege, curTotalDataCount } = useExport()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
      search,
      extendData,
      updateRouteQuery,
    } = useTable()

    extendData('search', {
      orderBy: null,
      status: null,
      filterTimeType: null,
      range: null,
      code: null,
      exchangeCode: null,
      member: null,
    })

    const routeQuery = computed(() => {
      const query = {
        ...search,
        page: tableOptions.page,
      }

      query.member = get(search, 'member.id')
      if (query.range) {
        delete query.range
        query.range1 = dayjs(search.range[0]).valueOf()
        query.range2 = dayjs(search.range[1]).valueOf()
      }

      return omitBy(query, (item) => !item)
    })

    const getDataPayload = computed(() => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        MemberId: get(search, 'member.id') || undefined,
        sort: search.orderBy || undefined,
        status: search.status || undefined,
        dateRangeType: search.filterTimeType || undefined,
        startAt: search.filterTimeType ? get(search.range, '[0]') : undefined,
        endAt: search.filterTimeType ? get(search.range, '[1]') : undefined,
        code: search.code || undefined,
        exchangeCode: search.exchangeCode || undefined,
      }

      return payload
    })

    const getTableData = async () => {
      await Promise.allSettled([
        fetchData(GetCouponRecord, getDataPayload.value),
        fetchDataCount(GetCouponRecordCount, getDataPayload.value),
      ])
    }

    const refresh = async (searchStatus = false) => {
      console.log('refresh')
      if (searchStatus) {
        tableOptions.page = 1
        if (!search.filterTimeType) {
          search.range = null;
        }
      }
      loading.table = true
      await getTableData()
      updateRouteQuery(routeQuery.value)
      loading.table = false
    }

    const formateExportData = (data) => {
      data = flatten(data)
      const sheetsData = []
      data.forEach((item) => {
        const couponType = get(item, 'type')
        const originType = get(item, 'originType')
        const usedAtDate = get(item, 'usedAt')
        const expAtDate = get(item, 'expAt')
        const giveAtDate = get(item, 'createdAt')

        const row = {
          會員名稱: get(item, 'Member.UserInfo.name') || '-',
          會員手機: get(item, 'Member.UserInfo.phone') || '-',
          票券來源: get(couponOriginTypeConfig, `${originType}.label`) || '-',
          票券編號: get(item, 'code'),
          票券種類: get(couponTypeConfig, `${item.type}.label`),
          票券名稱: get(item, 'Coupon.name'),
          兌換碼: get(item, 'exchangeCode') || '-',
          // 票券使用狀態: usedAtDate ? '已使用' : '未使用',
          票券使用狀態: get(couponStatusConfig, `${item.status}.label`),
          票券發送日期: giveAtDate ? formatDate(giveAtDate) : '-',
          票券使用日期: usedAtDate ? formatDate(usedAtDate) : '-',
          票券到期日期: expAtDate ? formatDate(expAtDate) : '-',
        }
        if (couponType !== 'exchange') delete row['兌換碼']

        sheetsData.push(row)
      })
      return sheetsData
    }

    const onExport = async (couponType, exportType) => {
      const payload = unref(getDataPayload)
      const couponTypeLabel = get(couponTypeConfig, `${couponType}.label`)
      payload.type = couponType
      payload.limit = 100
      let sheetName
      if (exportType === 'used') {
        exportTypeLabel.value = `匯出${couponTypeLabel}使用紀錄`
        exportState.title = `匯出${couponTypeLabel}使用紀錄`
        sheetName = `${couponTypeLabel}使用紀錄`
        payload.isUsed = true
      } else if (exportType === 'added') {
        exportTypeLabel.value = `匯出${couponTypeLabel}新增紀錄`
        exportState.title = `匯出${couponTypeLabel}新增紀錄`
        sheetName = `${couponTypeLabel}新增紀錄`
        payload.isUsed = false
      }

      try {
        modal.exportAdded = false
        modal.exportUsage = false
        exportState.title = '匯出中請稍後...'
        exportState.modal = true
        await simpleFetch(GetCouponRecordCount, getDataPayload.value,
          (res) => {
            exportState.dataCount = [res.count]
          }, true)

        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          if (exportType === 'used') window.$message.warning('篩選條件內無可匯出的使用紀錄')
          else if (exportType === 'added') window.$message.warning('篩選條件內無可匯出的新增紀錄')
          return
        }
        await getExportData({ stage: 0, fetchAPI: GetCouponRecord, payload: getDataPayload.value, throwErr: true })

        const formatData = formateExportData(exportState.exportData)
        exportState.exportting = false
        exportState.title = '匯出成功'

        ExportExcel(formatData, sheetName, sheetName)
      } catch (error) {
        exportState.error = true
        exportState.exportting = false
        window.$message.error(error)
      }

      // 取得所有資料
      // 格式化匯出資料
      // 匯出
    }

    const syncRouteQuery = () => {
      const query = route.currentRoute.query
      if (!query) return
      console.log(query)
      search.member = query.memebr
      console.log(query.memebr)
    }

    onMounted(() => {
      syncRouteQuery()
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      orderByOptionsConfig,
      refresh,
      search,
      couponStatusConfig,
      filterTimeCategoryConfig,
      modal,
      onExport,
      exportState,
      resetExport,
      getExportDataOld,
      totalDataCount,
      totalPercentege,
      curTotalDataCount,
      exportTypeLabel
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .test.el-select {
  max-width: 154px !important;
}
::v-deep .test .el-input {
  max-width: 154px !important;
}
::v-deep .test2.el-select {
  max-width: 208px !important;
}
::v-deep .test2.el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input {
  width: 100% !important;
  max-width: 208px !important;
}
::v-deep .test2 .el-input__inner {
  width: 100% !important
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}
</style>
